import React, {useEffect} from 'react';
import classes from "./notification.module.css";
import {CSSTransition} from "react-transition-group";

const Notification = ({open, setOpen, text}) => {
    useEffect(()=>{
        if(open)
            setTimeout(()=>{setOpen(false)},2000)
    },[open])

    return (
        <CSSTransition in={open}
                       timeout={300}
                       classNames="openNotification"
                       unmountOnExit
        >
            <div className={classes.block}>
                {text}
            </div>
        </CSSTransition>
    );
};

export default Notification;