import React, {useEffect, useState} from 'react';
import classes from "./prizes.module.css";
import lock from './lock.png'
import Button from "../button/button";
import Settings from "../settings/settings";
import energyF from './energy.png'
import {buyShops, buyShopsEnergy, getShops, getShopsEnergy} from "../../../utils/api";
import Money from "../money/money";
import goroskop from './goroskop.png'
import course from './course.png'
import cons from './cons.png'
import Popup from "../popup/popup";
import {toRub} from "../../../utils/money";

const Prizes = ({openSettings, setOpenSettings, info, energy, update}) => {
    const [infoCheck, setInfoCheck] = useState(false)
    const [moneyRain, setMoneyRain] = useState(false)
    const [energyUnlimit, setEnergyUnlimit] = useState([])
    const [shop, setShop] = useState([])
    const [openPrize, setOpenPrize] = useState(false)

    useEffect(()=>{
        if(info.fio && info.phone && info.email){
            setInfoCheck(true)
        }
    },[info])

    const getShopsF = async ()=>{
        const res = await getShops()
        setShop(res.items.filter(item => item.item.oid !== '31d00618-e480-4f35-8fd9-2695182969d0'))
        console.log(res)
        const res2 = await getShopsEnergy()
        setEnergyUnlimit(res2.items)
    }

    useEffect(()=>{
        getShopsF()
    },[])

    useEffect(()=>{
        if(moneyRain){
            setTimeout(()=>{setMoneyRain(false)},5000)
        }
    },[moneyRain])

    async function buyEnergy(){
        await buyShops('31d00618-e480-4f35-8fd9-2695182969d0')
        setMoneyRain(true)
        await getShopsF()
    }

    async function buyShop(oid){
        await buyShops(oid)
        setMoneyRain(true)
        await getShopsF()
    }

    async function buyUnlimitEnergy(unlimit_energy_oid){
        await buyShopsEnergy(unlimit_energy_oid)
        setMoneyRain(true)
        await getShopsF()
    }

    function getPrize(item){
        window.open(item.item.reward.link)
    }

    return (
        <div className={classes._}>
            {!infoCheck &&
                <div>
                    <div className={classes.lockBlock}>
                        <img src={lock} alt={''} className={classes.lock}/>
                    </div>
                    <div className={classes.text1}>Войдите чтобы получить
                        доступ к призам</div>
                    <Button active={true} onClick={()=>setOpenSettings(true)}>Войти</Button>
                </div>
            }
            {infoCheck &&
                <div className={classes.block}>
                    <Button active={true} onClick={()=>setOpenPrize(true)}>Ваши призы</Button>
                    <div>
                        <div className={classes.text2}>Бонусы от Азата Валеева</div>
                        <div className={classes.blockEnergy}>
                            {shop.map(item=>
                                <div key={item.item.oid}
                                     className={classes.blockPrize+' '+(info.balance >= item.item.cost && !item.is_buy && classes.blockPrizeSelect)}
                                     onClick={async ()=>{if(info.balance >= item.item.cost && !item.is_buy) await buyShop(item.item.oid)}}>
                                    <div className={classes.blockPrizeLeft}>
                                        <div className={classes.blockPrizeText}>{item.item.title}</div>
                                        <div className={classes.blockPrizeText2}>{item.is_buy?"Куплено":toRub(item.item.cost)}</div>
                                    </div>
                                    <div className={classes.blockPrizeRightG}>
                                        {item.item.oid === "cd84e4f8-fc76-475b-9227-8ce47157df52" &&
                                            <img src={goroskop} alt={''} className={classes.goroskop} style={{width:'76px'}}/>}
                                        {item.item.oid === "53b66187-2d5a-472c-9c2e-77dcf0d5e2c4" &&
                                            <img src={course} alt={''} className={classes.goroskop}/>}
                                        {item.item.oid === "744414d9-5c51-44aa-8089-0688134fb363" &&
                                            <img src={cons} alt={''} className={classes.goroskop}/>}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <div className={classes.text2}>Энергия</div>
                        <div className={classes.blockEnergy}>
                            <div className={classes.blockPrize+' '+(info.balance >= 100000 && classes.blockPrizeSelect)} onClick={buyEnergy}>
                                <div className={classes.blockPrizeLeft}>
                                    <div className={classes.blockPrizeText}>Восполнение энергии<br/>
                                        10 000 за единицу</div>
                                    <div className={classes.blockPrizeText2}>100 000 за 10 ед</div>
                                </div>
                                <div className={classes.blockPrizeRight}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                        <path d="M13.75 13.7505V7.50049C13.75 6.81013 14.3096 6.25049 15 6.25049C15.6904 6.25049 16.25 6.81013 16.25 7.50049V13.7505H22.5C23.1904 13.7505 23.75 14.3101 23.75 15.0005C23.75 15.6908 23.1904 16.2505 22.5 16.2505H16.25V22.5005C16.25 23.1908 15.6904 23.7505 15 23.7505C14.3096 23.7505 13.75 23.1908 13.75 22.5005V16.2505H7.5C6.80964 16.2505 6.25 15.6908 6.25 15.0005C6.25 14.3101 6.80964 13.7505 7.5 13.7505H13.75Z" fill="#44C8FF"/>
                                    </svg>
                                    <img src={energyF} alt={''} className={classes.energy}/>
                                </div>
                            </div>
                            {energyUnlimit.map(item=>
                                    <div key={item.oid}
                                         className={classes.blockPrize+' '+((info.balance >= item.cost && !energy.unlimit.is_active) && classes.blockPrizeSelect)}
                                         onClick={async ()=>{if(info.balance >= item.cost && !energy.unlimit.is_active) await buyUnlimitEnergy(item.oid)}}
                                    >
                                        <div className={classes.blockPrizeLeft}>
                                            <div className={classes.blockPrizeText}>Безлимитная энергия <br/> на {item.minutes} минут</div>
                                            <div className={classes.blockPrizeText2}>{toRub(item.cost)}</div>
                                        </div>
                                        <div className={classes.blockPrizeRight}>
                                            <img src={energyF} alt={''} className={classes.energy2_1}/>
                                            <img src={energyF} alt={''} className={classes.energy2}/>
                                            <img src={energyF} alt={''} className={classes.energy2_2}/>
                                        </div>
                                    </div>
                            )}
                        </div>
                    </div>
                </div>
            }
            <Settings open={openSettings} close={()=>setOpenSettings(false)} info={info} update={update} prizes={true}/>
            <Money trigger={moneyRain} type={1}/>
            <Popup open={openPrize} close={()=>setOpenPrize(false)}>
                <div className={classes.text2}>Ваши призы</div>
                <div className={classes.popup}>
                    {shop.filter(item => item.item.oid !== '31d00618-e480-4f35-8fd9-2695182969d0' && item.is_buy).map(item=>
                        <div key={item.item.oid}
                             className={classes.blockPrize}
                             onClick={async ()=>{getPrize(item)}}>
                            <div className={classes.blockPrizeLeft}>
                                <div className={classes.blockPrizeText}>{item.item.title}</div>
                                <div className={classes.blockPrizeText2}>{toRub(item.item.cost)}</div>
                            </div>
                            <div className={classes.blockPrizeRightG}>
                                {item.item.oid === "cd84e4f8-fc76-475b-9227-8ce47157df52" &&
                                    <img src={goroskop} alt={''} className={classes.goroskop} style={{width:'76px'}}/>}
                                {item.item.oid === "53b66187-2d5a-472c-9c2e-77dcf0d5e2c4" &&
                                    <img src={course} alt={''} className={classes.goroskop}/>}
                                {item.item.oid === "744414d9-5c51-44aa-8089-0688134fb363" &&
                                    <img src={cons} alt={''} className={classes.goroskop}/>}
                            </div>
                        </div>
                    )}
                </div>
            </Popup>
        </div>
    );
};

export default Prizes;