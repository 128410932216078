import Start from "./components/pages/start/start";
import FirstStart from "./components/pages/firstStart/firstStart";
import {useCallback, useEffect, useState} from "react";
import {CSSTransition} from "react-transition-group";
import Main from "./components/pages/main/main";
import {getMe, register} from "./utils/api";
import {useTelegram} from "./hooks/useTelegram";


function App() {
    const {webApp} = useTelegram()
    const [openStart, setOpenStart] = useState(true)
    const [openFirstStart, setOpenFirstStart] = useState(false)
    const [openMain, setOpenMain] = useState(false)
    const [info, setInfo] = useState(null)
    const [selectMenu, setSelectMenu] = useState(1)
    const [timer, setTimer] = useState(0)
    const [openQuiz, setOpenQuiz] = useState(false)


    useEffect(()=>{
        registerF()
    },[])

    useEffect(()=>{
        update()
    },[openMain, openFirstStart])

    useEffect(()=>{
        if(selectMenu === 5){
            if(openQuiz){
                setOpenQuiz(false)
            }
            else{
                setSelectMenu(1)
            }
        }
    },[timer])

    const onBackClick = useCallback(() => {
        setTimer(new Date)
    }, [])

    useEffect(() => {
        if(webApp.platform !== 'unknown'){
            webApp.ready()
            webApp.enableClosingConfirmation()
            webApp.expand()
            webApp.BackButton.onClick(onBackClick)
            return () => {
                webApp.BackButton.offClick(onBackClick)
            };
        }
    }, [webApp])

    async function update(){
        const res = await getMe()
        setInfo(res)
        console.log(res)
    }

    async function registerF(){
        const hash = window.location.hash.slice(1);
        const params = new URLSearchParams(hash);
        const tgWebAppData = new URLSearchParams(params.get('tgWebAppData'));
        const inviter_id = tgWebAppData.get('start_param');
        const res = await register(inviter_id)
        console.log(res)
        await update()
    }

    console.log('Version 0.6')

    return (
        <div className="App">
            <CSSTransition in={openStart}
                           timeout={500}
                           classNames="openBlock"
                           unmountOnExit
                           onExited={()=>{
                               if(info.balance===0 && info.level===1 && info.stage===1){
                                   setOpenFirstStart(true)
                               }else{
                                   setOpenMain(true)
                               }
                           }}
            >
                <Start setOpenStart={setOpenStart}/>
            </CSSTransition>
            <CSSTransition in={openFirstStart}
                           timeout={500}
                           classNames="openBlock"
                           unmountOnExit
                           onExited={()=>setOpenMain(true)}
            >
                <FirstStart setOpenFirstStart={setOpenFirstStart}/>
            </CSSTransition>
            <CSSTransition in={openMain}
                           timeout={500}
                           classNames="openBlock"
                           unmountOnExit
            >
                <Main info={info}
                      update={update}
                      selectMenu={selectMenu}
                      setSelectMenu={setSelectMenu}
                      openQuiz={openQuiz}
                      setOpenQuiz={setOpenQuiz}
                />
            </CSSTransition>
        </div>
    );
}

export default App;
