export function getTime(x){
    let sec = Math.floor(x/1000);
    let min = Math.floor(sec / 60);
    sec = sec - min * 60;
    return min.toString()+':'+sec.toString()
}

export function getTimeMin(x){
    let sec = Math.floor(x/1000);
    let min = Math.floor(sec / 60);
    return min
}