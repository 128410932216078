import React, {useEffect, useState} from 'react';
import classes from "./money.module.css";
import img from '../../../image/coin.png'
import energy from '../../../image/energy.png'

const Money = ({trigger, type}) => {
    const [coins, setCoins] = useState([]);

    useEffect(() => {
        if (trigger) {
            const newCoins = Array.from({ length: 30 }).map((_, index) => ({
                id: index,
                left: Math.random() * 100, // Рандомная позиция по горизонтали
                duration: Math.random() + 1, // Рандомная длительность анимации
                delay: Math.random(), // Рандомная заержка
            }));
            setCoins(newCoins);

            // Очистка после завершения анимации
            setTimeout(() => setCoins([]), 5000); // 5 секунд - длительность анимации
        }
    }, [trigger]);

    return (
        <div className={classes._}>
            {coins.map((coin) => (
                <img
                    key={coin.id}
                    src={type===0?img:energy}
                    alt="coin"
                    className={type===0?classes.coin:classes.energy}
                    style={{
                        left: `${coin.left}%`,
                        animationDuration: `${coin.duration}s`,
                        animationDelay: `${coin.delay}s`,
                    }}
                />
            ))}
        </div>
    );
};

export default Money;