import React, {use, useEffect, useState} from 'react';
import classes from "./settings.module.css";
import Popup from "../popup/popup";
import {useTelegram} from "../../../hooks/useTelegram";
import Button from "../button/button";
import {CSSTransition} from "react-transition-group";
import {profile} from "../../../utils/api";

const Settings = ({open, close, info, update, prizes}) => {
    const {user} = useTelegram()
    const [infoCheck, setInfoCheck] = useState(false)
    const [openInfo, setOpenInfo] = useState(false)
    const [valueName, setValueName] = useState(info.fio?info.fio:"")
    const [valueTel, setValueTel] = useState(info.phone?info.phone:"")
    const [valueEmail, setValueEmail] = useState(info.email?info.email:"")
    const [correctEmail, setCorrectEmail] = useState(false)
    const [correctPhone, setCorrectPhone] = useState(false)

    useEffect(()=>{
        if(info.fio && info.phone && info.email){
            setInfoCheck(true)
        }
        checkEmailMask(valueEmail)
        phoneFormating(valueTel)
    },[info])

    useEffect(()=>{
        if(prizes){
            setOpenInfo(true)
        }
    },[])


    async function saveInfo(){
        await profile(valueName, valueTel, valueEmail)
        setOpenInfo(false)
        setInfoCheck(true)
        update()
        if(prizes){
            close()
        }
    }

    function checkEmailMask(email){
        if (/.+@.+\.[A-Za-z]+$/.test(email)) {
            setCorrectEmail(true)
        } else {
            setCorrectEmail(false)
        }
    }


    function phoneFormating(value) {
        let processedValue = ''
        processedValue = value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 ($2) $3-$4-$5')
        if(processedValue.length === 18){
            setCorrectPhone(true)
        }else{
            setCorrectPhone(false)
        }
        return processedValue
    }

    return (
        <Popup open={open} close={close}>
            <div className={classes.avatarBlock}>
                {user?.photo_url ?
                    <img alt={''} src={user?.photo_url} className={classes.avatar}/> :
                    <div className={classes.avatarNone}>{user?.name[0]}</div>
                }
            </div>
            <div className={classes.buttonBlock}>
                <div className={classes.button}>Связь с поддержкой</div>
                <div className={classes.button}>Политика конфиденциальности</div>
                <div className={classes.button}>Заказать приложение</div>
                {infoCheck && <div className={classes.button} onClick={()=>setOpenInfo(true)}>Изменить данные</div>}
            </div>
            {!infoCheck &&
                <Button active={true} onClick={()=>setOpenInfo(true)}>Войти</Button>
            }
            <CSSTransition in={openInfo}
                           timeout={300}
                           classNames="openBlock"
                           unmountOnExit
            >
                <div className={classes.popup}>
                    <div>
                        <div className={classes.avatarBlockBig}>
                            {user?.photo_url ?
                                <img alt={''} src={user?.photo_url} className={classes.avatar+' '+classes.avatarBig}/> :
                                <div className={classes.avatarNone+' '+classes.avatarBig}>{user?.name[1]}</div>
                            }
                        </div>
                        <div className={classes.inputBlock}>
                            <input className={classes.input}
                                   placeholder={"Ваше имя"}
                                   value={valueName}
                                   onChange={e=>setValueName(e.target.value)}
                            />
                            <input className={classes.input}
                                   placeholder={"Ваш телефон"}
                                   value={valueTel}
                                   onChange={e=>{setValueTel(phoneFormating(e.target.value));}}
                            />
                            <input className={classes.input}
                                   placeholder={"Почта"}
                                   value={valueEmail}
                                   onChange={e=>{setValueEmail(e.target.value); checkEmailMask(e.target.value)}}/>
                        </div>
                    </div>
                    {infoCheck ?
                        <Button active={valueName !== "" && valueTel !== "" && valueEmail !== "" && correctEmail && correctPhone} onClick={saveInfo}>Сохранить</Button>:
                        <Button active={valueName !== "" && valueTel !== "" && valueEmail !== "" && correctEmail && correctPhone} onClick={saveInfo}>Войти</Button>
                    }
                </div>
            </CSSTransition>
        </Popup>
    );
};

export default Settings;