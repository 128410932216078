import React, {useEffect, useState} from 'react';
import classes from "./tasks.module.css";
import coin from "../../../image/coin.png";
import {getChannelSubscriptions, getDailyTasks, prizeChannelSubscriptions, prizeDailyTasks} from "../../../utils/api";
import Money from "../money/money";
import {toRub} from "../../../utils/money";
import Popup from "../popup/popup";
import Button from "../button/button";
import Notification from "../notification/notification";

const Tasks = () => {
    const [dailyTasks, setDailyTasks] = useState([])
    const [allTasks, setAllTasks] = useState([])
    const [moneyRain, setMoneyRain] = useState(false)
    const [openPopup, setOpenPopup] = useState(null)
    const [not, setNot] = useState(false)
    const [textNot, setTextNot] = useState('')
    const [code1, setCode1] = useState('')
    const [code2, setCode2] = useState('')

    useEffect(()=>{
        getDailyTasksF()
        getChannelSubscriptionsF()
    },[])

    useEffect(()=>{
        if(moneyRain){
            setTimeout(()=>{setMoneyRain(false)},5000)
        }
    },[moneyRain])

    const getDailyTasksF = async ()=>{
        const res = await getDailyTasks()
        setDailyTasks([
            {
                id:1,
                text:'Награда за ежедневный вход раз в три часа',
                coins: res.items.filter(item=>item.task_type==="ENTRY")[0].coins,
                is_active: res.items.filter(item=>item.task_type==="ENTRY")[0].is_active,
                task_type: "ENTRY"
            },
            {
                id:2,
                text:'Пройти 1 квиз за день',
                coins: res.items.filter(item=>item.task_type==="FIRST_QUIZ")[0].coins,
                is_active: res.items.filter(item=>item.task_type==="FIRST_QUIZ")[0].is_active,
                task_type: "FIRST_QUIZ"
            },
        ])
    }

    const getChannelSubscriptionsF = async ()=>{
        const res = await getChannelSubscriptions()
        console.log(res)
        setAllTasks(res.items)
    }

    async function setDTask(type){
        setMoneyRain(true)
        const res = await prizeDailyTasks(type)
        console.log(res)
        await getDailyTasksF()
    }

    async function setCSubscription(channel, code){
        let res = await prizeChannelSubscriptions(channel, code)
        if(res.is_subscribe){
            setMoneyRain(true)
            setOpenPopup(null)
        }else{
            if(channel!=="7ecaf928-7705-4a89-87da-157345837dbe"){
                setTextNot('Неверный код')
                setNot(true)
                setCode1('')
                setCode2('')
            }
            if(channel==="7ecaf928-7705-4a89-87da-157345837dbe"){
                setTextNot('Не найдена подписка')
                setNot(true)
            }
        }
        await getChannelSubscriptionsF()
    }

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.text1}>Ежедневный бонус</div>
                {dailyTasks.map(item=>
                    <div key={item.id} className={classes.task+' '+(item.is_active && classes.taskActive)} onClick={async ()=>{if(item.is_active) await setDTask(item.task_type)}}>
                        <div className={classes.taskText}>{item.text}</div>
                        <div className={classes.taskBlock}>
                            <div className={classes.taskPrice}>+{toRub(item.coins)}
                                <img alt={''} src={coin} className={classes.coin}/>
                            </div>
                            {item.is_active ? <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M10.0909 9.00004L5.79039 5.05786C5.36543 4.66831 5.36543 3.99835 5.7904 3.60881C6.16617 3.26435 6.74293 3.26435 7.1187 3.60881L13 9.00004L7.1187 14.3912C6.74293 14.7357 6.16618 14.7357 5.7904 14.3912C5.36544 14.0017 5.36544 13.3317 5.79041 12.9421L10.0909 9.00004Z" fill="#AE9367"/>
                            </svg>:<div className={classes.text1}>Получено</div>}
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.block}>
                <div className={classes.text1}>Финкоинов много не бывает</div>
                {allTasks.map(item=>
                    <div key={item.channel.oid}
                         className={classes.task+' '+(!item.is_subscribe && classes.taskActive)}
                         onClick={()=>{setOpenPopup(item)}}
                    >
                        <div className={classes.taskText}>{item.channel.name}</div>
                        <div className={classes.taskBlock}>
                            <div className={classes.taskPrice}>+{toRub(item.channel.coin_reward)}
                                <img alt={''} src={coin} className={classes.coin}/>
                            </div>
                            {item.is_subscribe?
                                <div className={classes.text1}>Получено</div>:
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M10.0909 9.00004L5.79039 5.05786C5.36543 4.66831 5.36543 3.99835 5.7904 3.60881C6.16617 3.26435 6.74293 3.26435 7.1187 3.60881L13 9.00004L7.1187 14.3912C6.74293 14.7357 6.16618 14.7357 5.7904 14.3912C5.36544 14.0017 5.36544 13.3317 5.79041 12.9421L10.0909 9.00004Z" fill="#AE9367"/>
                                </svg>
                            }
                        </div>
                    </div>
                )}
            </div>
            <Money trigger={moneyRain} type={0}/>
            <Popup open={openPopup} close={()=>{setOpenPopup(null); setCode1('');setCode2('')}}>
                {openPopup?.channel?.oid === "7ecaf928-7705-4a89-87da-157345837dbe" && <div className={classes.popupBlock}>
                    <div className={classes.popupText}>{openPopup?.channel?.name}</div>
                    <Button active={true} onClick={()=>{window.open(openPopup?.channel?.link)}}>Подписаться</Button>
                    <Button active={true} onClick={async ()=>{await setCSubscription(openPopup?.channel?.oid)}}>Проверить</Button>
                </div>}
                {openPopup?.channel?.oid === "6fa61346-bf3a-47a4-a7ca-6f42a8d3356c" && <div className={classes.popupBlock}>
                    <div className={classes.popupText}>{openPopup?.channel?.name}<br/>Для получения награды введи код.</div>
                    <Button active={true} onClick={()=>{window.open(openPopup?.channel?.link)}}>Перейти</Button>
                    <input className={classes.input}
                           placeholder={'Код'}
                           value={code2}
                           onChange={e=>setCode2(e.target.value)}
                    />
                    <Button active={code2 !== ''} onClick={async ()=>{await setCSubscription(openPopup?.channel?.oid, code2)}}>Проверить</Button>
                </div>}
                {openPopup?.channel?.oid === "14abeb29-e178-4d11-89c8-2515714a8588" && <div className={classes.popupBlock}>
                    <div className={classes.popupText}>{openPopup?.channel?.name}.<br/>Для получения награды введи код, который был в видеo.</div>
                    <Button active={true} onClick={()=>{window.open(openPopup?.channel?.link)}}>Посмотреть</Button>
                    <input className={classes.input}
                           placeholder={'Код'}
                           value={code1}
                           onChange={e=>setCode1(e.target.value)}
                    />
                    <Button active={code1 !== ''} onClick={async ()=>{await setCSubscription(openPopup?.channel?.oid, code1)}}>Проверить</Button>
                </div>}
            </Popup>
            <Notification text={textNot} open={not} setOpen={setNot}/>
        </div>
    );
};

export default Tasks;