import React, {useEffect, useState} from 'react';
import classes from "./start.module.css";

const Start = ({setOpenStart}) => {
    const [text, setText] = useState({
        text:'Будущее принадлежит тем, кто верит в свои мечты и инвестирует в них',
        name:'Франклин Рузвельт'
    })

    useEffect(()=>{
        const texts = [
            {"text": "Пошаговый финансовый план — ваш первый шаг к стабильности и свободе"},
            {"text": "Хотите увеличить доходы в 2-3 раза? Всё начинается с грамотного плана"},
            {"text": "Персональный финансовый план поможет вам закрыть кредиты и освободиться от долгов"},
            {"text": "Накопить на машину за несколько месяцев? Финансовый план покажет, как это сделать"},
            {"text": "Правильный финансовый план — путь к пассивному доходу от 100 тысяч рублей без лишних усилий"},
            {"text": "Ваш финансовый потолок пробить легко, если следовать плану, который действительно работает"},
            {"text": "Позвольте деньгам работать за вас, пока вы наслаждаетесь жизнью"},
            {"text": "Каждый день — это шанс приблизиться к свободной и стабильной жизни"},
            {"text": "Уверенность в будущем начинается с одного разумного вложения"},
            {"text": "Мечтаете о пассивном доходе? Всё возможно, если сделать первый шаг!"},
            {"text": "Ваше будущее в ваших руках — начните строить его уже сегодня!"},
            {"text": "Создавайте капитал, который останется в семье на поколения вперёд"},
            {"text": "Когда я в последний раз зарабатывал пока спал? Ах да, вчера, в этой игре!"},
            {"text": "Инвестировать — это как садоводство: главное, чтобы всё не засохло"},
            {"text": "Мечтаете о доходе, который растёт сам? Всё возможно!"},
            {"text": "Хотите закрыть все кредиты и жить по-настоящему? Начните прямо сейчас!"},
            {"text": "Ваш путь к машине, новому дому и путешествиям начинается здесь!"},
            {"text": "Простой способ получать дополнительные 100 тысяч рублей каждый месяц — правильные инвестиции"},
            {"text": "Ваша финансовая свобода начинается здесь"},
            {"text": "Накопить на мечту легко, если мечтаешь правильно"},
            {"text": "Каждое вложение — это как свидание: хочется, чтобы переросло во что-то серьёзное"},
            {"text": "Финансовый потолок? Что ж, мы принесли молоток!"},
            {"text": "Как насчёт дохода, который не уйдёт в отпуск без вас?"},
            {"text": "Даже если деньги не пахнут, деньги, растущие сами по себе, всегда благоухают"},
            {"text": "Осторожно: в этом приложении ваш капитал может вырасти в 2 раза!"},
            {"text": "Каждое большое достижение начинается с малого шага", "name": "Аристотель"},
            {"text": "Финансовая свобода — это когда деньги работают на вас, а не наоборот", "name": "Роберт Кийосаки"},
            {"text": "Самое лучшее время для инвестиций было вчера. Следующее — сегодня!", "name": "Джон Темплтон"},
            {"text": "Не деньги делают нас счастливыми, а умение управлять ими", "name": "Бенджамин Франклин"},
            {"text": "Инвестиции — это когда деньги превращаются в ваш самый прибыльный актив", "name": "Уоррен Баффет"},
            {"text": "Будущее принадлежит тем, кто верит в свои мечты и инвестирует в них", "name": "Франклин Рузвельт"}
        ]
        setText(texts[Math.floor(Math.random() * texts.length)])
    },[])

    useEffect(()=>{
        setTimeout(()=>setOpenStart(false),3000)
    },[])

    return (
        <div className={classes._} onClick={()=>setOpenStart(false)}>
            <div className={classes.text1}>{text.text}</div>
            <div className={classes.text2}>{text.name}</div>
        </div>
    );
};

export default Start;