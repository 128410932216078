export function toRub(value){
    let t = value.toString().split('.')[0]
    let out = ''
    for(let i = 0; i < t.length; i++){
        if(i > 0 && ((i)/3 - Math.trunc((i)/3)) === 0){
            out += ' '
        }
        out += t[t.length - 1 - i]
    }
    out = out.split('').reverse().join('')
    if(t.split('.').length > 1){
        out += '.' + t.split('.')[1]
    }
    return out.toString()
}