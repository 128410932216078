import {API_URL} from "../config";
const token = window.Telegram?.WebApp?.initData

export async function signUpQuestions(gender,monthly_income,loans,financial_reserves,passive_income){
    let data = {
        gender: gender,
        monthly_income: monthly_income,
        loans: loans,
        financial_reserves: financial_reserves,
        passive_income: passive_income
    }
    let response = await fetch(API_URL+'user/sign-up-questions',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function register(inviter_id){
    let data = {
        inviter_id: inviter_id,
    }
    let response = await fetch(API_URL+'user/sign-up',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function profile(fio,phone,email){
    let data = {
        fio: fio,
        phone: phone,
        email: email
    }
    let response = await fetch(API_URL+'user/update-personal-info',{
        method:'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getMe(){
    try{
        let response = await fetch(API_URL+'user/me',{
            method:'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        return await response.json()
    }catch (error) {
        console.error(error);
    }
}

export async function getEnergy(){
    try{
        let response = await fetch(API_URL+'user/energy',{
            method:'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        return await response.json()
    }catch (error) {
        console.error(error);
        return {"energy":0}
    }
}

export async function getReferrals(){
    let response = await fetch(API_URL+'user/refferal-users',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function getCurrentGame(){
    let response = await fetch(API_URL+'game/current',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function getStartGame(){
    let response = await fetch(API_URL+'game/start',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function setEndGame(game_oid,num_correct_answers,duration){
    let data = {
        game_oid: game_oid,
        num_correct_answers: num_correct_answers,
        duration: Math.floor(duration)
    }
    let response = await fetch(API_URL+'game/end',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getDailyTasks(){
    let response = await fetch(API_URL+'user/daily-tasks-status',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function prizeDailyTasks(type){
    let data = {
        task_type: type
    }
    let response = await fetch(API_URL+'user/daily-tasks-status',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getChannelSubscriptions(){
    let response = await fetch(API_URL+'channel-subscriptions',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function prizeChannelSubscriptions(channel_oid, code){
    let data = {
        channel_oid: channel_oid,
        code:code
    }
    let response = await fetch(API_URL+'channel-subscriptions',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getShops(){
    let response = await fetch(API_URL+'shops',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function buyShops(shop_item_oid){
    let data = {
        shop_item_oid: shop_item_oid
    }
    let response = await fetch(API_URL+'shops',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}

export async function getShopsEnergy(){
    let response = await fetch(API_URL+'shops/unlimit-energy',{
        method:'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });
    return await response.json()
}

export async function buyShopsEnergy(unlimit_energy_oid){
    let data = {
        unlimit_energy_oid: unlimit_energy_oid
    }
    let response = await fetch(API_URL+'shops/unlimit-energy',{
        method:'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return await response.json()
}