import React, {useEffect, useRef} from 'react';
import classes from "./prize.module.css";
import {CSSTransition} from "react-transition-group";
import prizeI from '../../../image/prize.png'
import energyI from "../../../image/energy.png";
import coin from "../../../image/coin.png";
import Button from "../button/button";

const Prize = ({open, close, prize}) => {


    return (
        <CSSTransition in={open}
                       timeout={300}
                       classNames="openBlock"
                       unmountOnExit
        >
            <div className={classes._}>
                <div className={classes.block}>
                    <div className={classes.block2}>
                        <img src={prizeI} alt={''} className={classes.prize}/>
                        <div className={classes.text1}>Твоя награда!</div>
                        <div className={classes.popupBlock}>
                            <div className={classes.popupBlock1}>
                                +{prize?.energy}
                                <img src={energyI} alt={''} className={classes.energy}/>
                            </div>
                            <div className={classes.popupBlock1}>
                                <img src={coin} alt={''} className={classes.coin}/>
                                +{prize?.coins}
                            </div>
                        </div>
                        {prize?.book && <div className={classes.popupBlock2}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM13 12V8H11V12H8L12 16L16 12H13Z" fill="#AE9367"/>
                            </svg>
                            {prize?.book}
                        </div>}
                    </div>
                    <Button active={true} onClick={close}>Дальше</Button>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Prize;