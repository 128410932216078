import React, {useEffect, useState} from 'react';
import classes from "./quizGame.module.css";
import {CSSTransition} from "react-transition-group";
import Button from "../button/button";
import Popup from "../popup/popup";
import coin from '../../../image/coin.png'
import fini1 from './fini1.png'
import fini2 from './fini2.png'
import {getTime, getTimeMin} from "../../../utils/time";
import Prize from "../prize/prize";
import {setEndGame} from "../../../utils/api";
import Money from "../money/money";

const QuizGame = ({item, open, close}) => {
    const [select, setSelect] = useState(item.questions[0])
    const [count, setCount] = useState(0)
    const [countTrues, setCountTrues] = useState(0)
    const [check, setCheck] = useState(null)
    const [openTrues, setOpenTrues] = useState(false)
    const [finish, setFinish] = useState(false)
    const [timerStart, setTimerStart] = useState(0)
    const [timerFinish, setTimerFinish] = useState(0)
    const [openHelp, setOpenHelp] = useState(false)
    const [openPrize, setOpenPrize] = useState(false)
    const [moneyRain, setMoneyRain] = useState(false)
    const [prize, setPrize] = useState(null)

    useEffect(()=>{
        if(timerStart === 0 && open)
            setTimerStart(new Date().getTime())
        }, [open])

    useEffect(()=>{
        setSelect(item.questions[count])
    },[item])

    useEffect(()=>{
        if(moneyRain){
            setTimeout(()=>{setMoneyRain(false)},5000)
        }
    },[moneyRain])

    async function answer(i){
        if(!check){
            setCheck(i)
            if(i.is_correct){
                let countTruesT = countTrues+1
                setCountTrues(countTruesT)
                setTimeout(async ()=>{await next(countTruesT)},1000)
            }else{
                setOpenTrues(true)
            }
        }
    }

    async function next(countTruesT){
        if(count+1===item.questions.length){
            setCheck(null)
            setOpenTrues(false)
            setFinish(true)
            const dF = new Date().getTime()
            setTimerFinish(dF)
            const res = await setEndGame(item.game_oid, countTruesT, (dF-timerStart)/1000)
            console.log(res)
            if(countTruesT > 6 && res.level_reward){
                setPrize(res.level_reward)
            }
        }else{
            setSelect(item.questions[count+1])
            setCount(count+1)
            setCheck(null)
            setOpenTrues(false)
        }
    }

    async function closeStart(){
        if(prize && countTrues > 6){
            setOpenPrize(true)
            setMoneyRain(true)
        }else{
            close()
        }
        setCount(0)
        setCountTrues(0)
        setFinish(false)
        setTimerStart(0)
        setTimerFinish(0)
    }

    return (
        <CSSTransition in={open}
                       timeout={500}
                       classNames="openBlock"
                       unmountOnExit
        >
            <div className={classes._}>
                <div className={classes.block}>
                    <div className={classes.textTop}>
                        Вопрос {count+1} из {item.questions.length}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className={classes.help} onClick={()=>setOpenHelp(true)}>
                            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM13 13.3551C14.4457 12.9248 15.5 11.5855 15.5 10C15.5 8.067 13.933 6.5 12 6.5C10.302 6.5 8.88637 7.70919 8.56731 9.31346L10.5288 9.70577C10.6656 9.01823 11.2723 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.4477 11.5 11 11.9477 11 12.5V14H13V13.3551Z" fill="#FFC629"/>
                        </svg>
                    </div>
                    <div className={classes.line}>
                        <div className={classes.line2} style={{width:(count).toString()+'0%'}}></div>
                    </div>
                    <div className={classes.question}>{select.text}</div>
                    <div className={classes.answerBlock}>
                        {select.answers.map((i, number) =>
                            <div key={number} className={classes.answer+' '+(check===i && (i.is_correct ? classes.answer3 : classes.answer2))} onClick={()=>answer(i)}>
                                <div className={classes.answerText}>{i.text}</div>
                                <svg className={classes.answerPoint+' '+(check && classes.answerPointHide)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M10.0909 9.00004L5.79039 5.05786C5.36543 4.66831 5.36543 3.99835 5.7904 3.60881C6.16617 3.26435 6.74293 3.26435 7.1187 3.60881L13 9.00004L7.1187 14.3912C6.74293 14.7357 6.16618 14.7357 5.7904 14.3912C5.36544 14.0017 5.36544 13.3317 5.79041 12.9421L10.0909 9.00004Z" fill="#AE9367"/>
                                </svg>
                            </div>
                        )}
                    </div>
                </div>
                <Popup open={openTrues} close={next}>
                    <div className={classes.openTruesHeader}>Правильный ответ</div>
                    <div className={classes.answer} style={{marginBottom:'10px'}}>{select.answers.filter(i=>i.is_correct)[0].text}</div>
                    <Button active={true} onClick={next}>Дальше</Button>
                </Popup>
                <Popup open={openHelp} close={()=>setOpenHelp(false)}>
                    <div className={classes.openTruesHeader}>Подсказка</div>
                    <div className={classes.answer} style={{marginBottom:'10px'}}>{select.clue}</div>
                    <Button active={true} onClick={()=>setOpenHelp(false)}>Дальше</Button>
                </Popup>
                <CSSTransition in={finish}
                               timeout={500}
                               classNames="openBlock"
                               unmountOnExit
                >
                    <div className={classes._}>
                        <div className={classes.finish}>
                            {countTrues > 6 ?<img alt={''} src={fini1} className={classes.fini}/>:
                                <img alt={''} src={fini2} className={classes.fini2}/>
                            }
                            {countTrues > 6 ?<div className={classes.finishText1}>Прекрасно!</div>:
                                <div className={classes.finishText1}>В следующий раз все получится</div>
                            }
                            <div className={classes.finishBlocks}>
                                <div className={classes.finishBlock}>
                                    <div className={classes.finishText2+' '+(countTrues<=6 && classes.finishText2_1)}>{countTrues}/{item.questions.length}</div>
                                    <div className={classes.finishText3}>Правильных ответов</div>
                                </div>
                                <div className={classes.finishBlock}>
                                    <div className={classes.finishText2}>{getTime(timerFinish-timerStart)}</div>
                                    <div className={classes.finishText3}>Бонус за скорость</div>
                                </div>
                            </div>
                            <div className={classes.finishBlocks}>
                                <div className={classes.finishBlock}>
                                    <div className={classes.finishText4}>+{countTrues>6?countTrues*100:0}
                                        <img alt={''} src={coin} className={classes.coin}/>
                                    </div>
                                </div>
                                <div className={classes.finishBlock}>
                                    <div className={classes.finishText4}>{getTimeMin(timerFinish-timerStart)<=1?'+300':'0'}
                                        <img alt={''} src={coin} className={classes.coin}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.finishBlock2}>
                            <Button active={true} onClick={closeStart}>Дальше</Button>
                        </div>
                    </div>
                </CSSTransition>
                <Prize open={openPrize}
                       close={()=>{setOpenPrize(false); setPrize(null); close()}}
                       prize={prize}
                />
                <Money trigger={moneyRain} type={0}/>
            </div>
        </CSSTransition>

    );
};

export default QuizGame;