import React, {useEffect, useState} from 'react';
import classes from "./main.module.css";
import Header from "../../common/header/header";
import fini2 from '../../../image/fini2.png'
import fon from '../../../image/fon.png'
import coin from '../../../image/coin.png'
import Button from "../../common/button/button";
import Menu from "../../common/menu/menu";
import Referrals from "../../common/refferals/referrals";
import Tasks from "../../common/tasks/tasks";
import Game from "../../common/game/game";
import {getEnergy} from "../../../utils/api";
import {useTelegram} from "../../../hooks/useTelegram";
import Prizes from "../../common/prizes/prizes";
import {toRub} from "../../../utils/money";

const Main = ({info, update, selectMenu, setSelectMenu, openQuiz, setOpenQuiz}) => {
    const {webApp} = useTelegram()
    const [balance, setBalance] = useState(info.balance)
    const [energy, setEnergy] = useState({energy:0, unlimit: {is_active: false, duration: null}})
    const [openSettings, setOpenSettings] = useState(false)

    useEffect(()=>{
        updateEnergy()
        setInterval(updateEnergy, 10000)
    },[])

    const updateEnergy = async ()=>{
        const res = await getEnergy()
        setEnergy(res)
    }

    useEffect(()=>{
        update()
        if(selectMenu === 5){
            webApp.BackButton.show()
        }else{
            webApp.BackButton.hide()
        }
    },[selectMenu])

    useEffect(()=>{
        setBalance(info.balance)
    },[info])

    return (
        <div className={classes._}>
            <div>
                {(selectMenu===1 || selectMenu===2) &&
                    <Header info={info}
                            level={info.level}
                            part={(info.stage-1) * 33}
                            energy={energy}
                            openSettings={openSettings}
                            setOpenSettings={setOpenSettings}
                            update={update}
                    />
                }
                {selectMenu===1 && <div className={classes.balance}>
                    <img src={coin} alt={''} className={classes.coin}/>
                    {toRub(balance)}
                </div>}
                {selectMenu===2 &&
                    <Referrals/>
                }
                {selectMenu===3 &&
                    <Tasks/>
                }
                {selectMenu===4 &&
                    <Prizes info={info}
                            openSettings={openSettings}
                            setOpenSettings={setOpenSettings}
                            energy={energy}
                            update={update}
                    />
                }
            </div>
            {selectMenu===1 &&
                <div className={classes.fonBlock}>
                    <img src={fon} alt={''} className={classes.fon}/>
                    <img src={fini2} alt={''} className={classes.fini}/>
                </div>
            }
            {selectMenu !==5 && <div className={classes.block}>
                {selectMenu===1 && <div className={classes.blockButton}>
                    <Button active={true} onClick={()=>setSelectMenu(5)}>Играть</Button>
                </div>}
                <Menu selectMenu={selectMenu} setSelectMenu={setSelectMenu}/>
            </div>}
            {selectMenu===5 && <Game balance={toRub(balance)}
                                     energy={energy}
                                     info={info}
                                     update={update}
                                     selectMenu={selectMenu}
                                     setSelectMenu={setSelectMenu}
                                     openQuiz={openQuiz}
                                     setOpenQuiz={setOpenQuiz}
            />}
        </div>
    );
};

export default Main;