import React, {useEffect, useState} from 'react';
import classes from "./firstStart.module.css";
import image1 from '../../../image/logo1.png'
import Button from "../../common/button/button";
import Quiz from "../../common/quiz/quiz";
import {CSSTransition} from "react-transition-group";
import Confetti from 'react-confetti';
import Popup from "../../common/popup/popup";
import energy from '../../../image/energy.png';
import coin from '../../../image/coin.png'
import {signUpQuestions} from "../../../utils/api";

const FirstStart = ({setOpenFirstStart}) => {
    const [step, setStep] = useState(0)
    const [showConfetti, setShowConfetti] = useState(true);
    const [answers, setAnswers] = useState([])

    const quiz = [
        {id:1, question:'Ваш пол', answer:[
                {id:1, text:'Мужской', check:'male'},
                {id:2, text:'Женский', check:'female'}
            ]},
        {id:2, question:'Ежемесячный доход', answer:[
                {id:1, text:'До 50 000₽', check: '< 50000'},
                {id:2, text:'50 000-100 000₽', check: '50000 - 100000'},
                {id:3, text:'100 000–200 000₽', check: '100000 - 200000'},
                {id:4, text:'Более 200 000₽', check: '< 200000'}
            ]},
        {id:3, question:'Есть ли у вас кредиты или долги?', answer:[
                {id:1, text:'Есть до 100 000₽', check: '< 100000'},
                {id:2, text:'Есть более 100 000₽', check: '> 100000'},
                {id:3, text:'Более 200 000₽', check: '> 200000'},
                {id:4, text:'Нет долгов и кредитов', check: 'нет'}
            ]},
        {id:4, question:' Есть ли у вас финансовая подушка безопасности?', answer:[
                {id:1, text:'Нет', check: 'NO'},
                {id:2, text:'Есть, меньше мес. дохода', check: 'UP_MONTH_INCOME'},
                {id:3, text:'Есть на 1-3 месячных дохода', check: 'Есть на 1-3 месячных дохода'},
                {id:4, text:'Есть на +3 мес. дохода', check: 'Есть на +3 месячных дохода'}
            ]},
        {id:5, question:'Какой пассивный доход вы бы хотели получать в месяц?', answer:[
                {id:1, text:'50 000₽', check: '50000'},
                {id:2, text:'100 000₽', check: '100000'},
                {id:3, text:'Более 100 000₽', check: '> 100000'}
            ]}
    ]

    useEffect(()=>{
        setTimeout(()=>setShowConfetti(false),3000)
    },[])

    useEffect(()=>{
        if(step === quiz.length+1){
            setShowConfetti(true)
            setTimeout(()=>setShowConfetti(false),3000)
        }
    },[step])

    async function finish(){
        console.log(answers)
        await signUpQuestions(answers[0],answers[1],answers[2],answers[3],answers[4])
        setOpenFirstStart(false)
    }

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.block1}>
                    <img src={image1} alt={''} className={classes.image1}/>
                    <div className={classes.text1}>Поздравляю<br/> с регистрацией!</div>
                    <div className={classes.text2}>Ответьте на несколько вопросов<br/> и получите первый подарок на пути<br/> к вашей финансовой свободе</div>
                </div>
                <Button active={true} onClick={()=>{setStep(1); setShowConfetti(false)}}>Начать</Button>
            </div>
            {quiz.map((item, number)=>
                <Quiz key={item.id}
                      item={item}
                      open={step>=number+1}
                      answers={answers}
                      next={()=>setStep(step+1)}
                      textTop={`Вопрос ${number+1} из ${quiz.length}`}/>
            )}
            <Popup open={step===quiz.length+1} close={finish}>
                <div className={classes.popupText1}>Твоя награда</div>
                <div className={classes.popupBlock}>
                    <div className={classes.popupBlock1}>
                        +10
                        <img src={energy} alt={''} className={classes.energy}/>
                    </div>
                    <div className={classes.popupBlock1}>
                        <img src={coin} alt={''} className={classes.coin}/>
                        5000
                    </div>
                </div>
                <div className={classes.popupBlock2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM13 12V8H11V12H8L12 16L16 12H13Z" fill="#AE9367"/>
                    </svg>
                    10 способов создать финансовую подушку безопасности
                </div>
                <Button active={true} onClick={finish}>Получить</Button>
            </Popup>
            <CSSTransition in={showConfetti}
                           timeout={300}
                           classNames="openBlock"
                           unmountOnExit
            >
                <Confetti
                    width={window.innerWidth}
                    height={window.innerHeight}
                    style={{zIndex:22}}
                />
            </CSSTransition>
        </div>
    );
};

export default FirstStart;