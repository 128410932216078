import React, {useEffect, useState} from 'react';
import classes from "./referrals.module.css";
import Button from "../button/button";
import {useTelegram} from "../../../hooks/useTelegram";
import {getReferrals} from "../../../utils/api";

const Referrals = () => {
    const {user} = useTelegram()
    const [notification,setNotification] = useState(false)
    const [referrals, setReferrals] = useState([])

    useEffect(()=>{
        const getReferralsF = async ()=>{
            const res = await getReferrals()
            console.log(res.referrals)
            setReferrals(res.referrals)
        }
        getReferralsF()
    },[])

    const shareLink = () => {
        let text = `https://t.me/ZlatogorieBot/app?startapp=${user.id}`
        let textAreaValue = '🔥 Узнай, как создать пассивный доход и прокачать свои финансы\n\n' +
            '💰 Играй и зарабатывай! Отвечай на вопросы, изучай инвестиции и получай реальные призы: гайды, курсы и даже финансовую консультацию от команды Азата Валеева.\n\n' +
            '👉 Заходи, проверим, кто пройдёт дальше и быстрее! \n\n'
        window.open(
            `https://t.me/share/url?url=${text}&text=${textAreaValue}`
        );
    };

    const copyLink = () => {
        let text = `https://t.me/ZlatogorieBot/app?startapp=${user.id}`
        navigator.clipboard.writeText(text);
        setNotification(true);
        setTimeout(() => setNotification(false), 2000);
    }

    return (
        <div className={classes._}>
            <div className={classes.block1}>
                <div className={classes.block}>
                    <div className={classes.text1}>+1 000</div>
                    <div className={classes.text2}>Получишь<br/>ты и друг</div>
                </div>
                <div className={classes.block}>
                    <div className={classes.text1}>+1 500</div>
                    <div className={classes.text2}>Получишь<br/> если у друга<br/>Telegram Premium</div>
                </div>
            </div>
            <div className={classes.block2}>
                <div className={classes.text3Block}>
                    <div className={classes.text3}>Топ друзей</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M10.8133 11.4143C9.79074 12.2985 8.45776 12.8334 6.99984 12.8334C3.77817 12.8334 1.1665 10.2217 1.1665 7.00002C1.1665 3.77836 3.77817 1.16669 6.99984 1.16669C10.2215 1.16669 12.8332 3.77836 12.8332 7.00002C12.8332 8.24608 12.4425 9.4009 11.7769 10.3487L9.9165 7.00002H11.6665C11.6665 4.42269 9.57718 2.33335 6.99984 2.33335C4.42251 2.33335 2.33317 4.42269 2.33317 7.00002C2.33317 9.57736 4.42251 11.6667 6.99984 11.6667C8.25412 11.6667 9.39279 11.1718 10.2314 10.3668L10.8133 11.4143Z" fill="white"/>
                    </svg>
                </div>
                <div className={classes.text3}>Получено</div>
            </div>
            <div className={classes.refBlock}>
                {referrals.map(item=>
                    <div key={item.oid} className={classes.ref}>
                        <div className={classes.refBlock1}>
                            <div className={classes.blockImage}>
                                {(item.avatar && item.avatar!=='') ?
                                    <img alt={''} src={item.photo} className={classes.avatar}/> :
                                    <div>{item.name[0]}</div>
                                }
                            </div>
                            <div>{item.first_name+' '+item.last_name}</div>
                        </div>
                        <div className={classes.refCount}>1000</div>
                    </div>
                )}
            </div>
            <div className={classes.copyBlock}>
                <Button active={true} onClick={shareLink}>Пригласить друга</Button>
                <div className={classes.copy} onClick={copyLink}>
                    {notification && <div className={classes.notification}>Скопировано!</div>}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z" fill="#FFC629"/>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Referrals;