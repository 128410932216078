import React, {useEffect, useRef, useState} from 'react';
import classes from "./game.module.css";
import background from './game.svg'
import energyImage from "../../../image/energy.png";
import coin from "../../../image/coin.png";
import {CSSTransition} from "react-transition-group";
import fini from "../../../image/fini.png";
import prize from "../../../image/prize.png";
import Button from "../button/button";
import QuizGame from "../quizGame/quizGame";
import {getStartGame} from "../../../utils/api";
import {getTime} from "../../../utils/time";

const Game = ({energy, balance, info, update, openQuiz, setOpenQuiz}) => {
    const ref = useRef()
    const [levels, setLevels] = useState([
        {id:1}
    ])
    const [activeLevel, setActiveLevel] = useState(info.level)
    const [openStartLevel, setOpenStartLevel] = useState(false)
    const [quiz, setQuiz] = useState({
        number:1,
        questions: [
            {id:0,
                text: 'Есть ли у вас кредиты или долги?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', is_correct:true},
                    {id:1, text:'100 000', flag:false},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:1,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', is_correct:true},
                    {id:2, text:'150 000', flag:false},
                ]},
            {id:3,
                text: 'Есть ли у вас кредиты или долги 2?',
                clue: 'Конечно есть!',
                answers:[
                    {id:0, text:'50 000', flag:false},
                    {id:1, text:'100 000', flag:true},
                    {id:2, text:'150 000', flag:false},
                ]}
    ]})
    const [time, setTime] = useState( energy.unlimit.duration + (new Date)/1000)

    const styles = [
        {marginLeft:'-64px'},
        {marginLeft:'0'},
        {marginRight:'-64px'},
        {marginLeft:'0'}
    ]

    useEffect(()=>{
        let temp = [{id:1}]
        for(let i=0; i<99; i++){
            temp.push({id:i+2})
        }
        setLevels(temp.reverse())
        setTimeout(()=>{ref.current.scrollIntoView({block: "center", inline: "center", behavior: "smooth"})}, 500);
    },[])

    function updateTime(){
        setTime(time - (new Date) / 1000)
        console.log(time)
    }

    useEffect(()=>{
       if(energy.unlimit.is_active){
           updateTime()
           setInterval(updateTime,1000)
       }
    },[])

    useEffect(()=>{
        update()
    },[openStartLevel,openQuiz])

    useEffect(()=>{
        setActiveLevel(info.level)
    },[info])

    async function play(){
        const res = await getStartGame()
        setQuiz(res)
        console.log(res)
        setOpenQuiz(true)
    }

    return (
        <div className={classes._}>
            <div className={classes.header}>
                <div className={classes.headerBlock}>
                    <div className={classes.headerCount}>
                        <img alt={''} src={energyImage} className={classes.energy}/>
                        {energy.unlimit.is_active?
                            <>∞ {getTime(time*1000)}</>:
                            <>{energy.energy}/10</>
                        }
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" onClick={()=>{ref.current.scrollIntoView({block: "center", inline: "center", behavior: "smooth"});}}>
                        <path d="M9.16663 9.16699V5.00033C9.16663 4.54009 9.53972 4.16699 9.99996 4.16699C10.4602 4.16699 10.8333 4.54009 10.8333 5.00033V9.16699H15C15.4602 9.16699 15.8333 9.54009 15.8333 10.0003C15.8333 10.4606 15.4602 10.8337 15 10.8337H10.8333V15.0003C10.8333 15.4606 10.4602 15.8337 9.99996 15.8337C9.53972 15.8337 9.16663 15.4606 9.16663 15.0003V10.8337H4.99996C4.53972 10.8337 4.16663 10.4606 4.16663 10.0003C4.16663 9.54009 4.53972 9.16699 4.99996 9.16699H9.16663Z" fill="#44C8FF"/>
                    </svg>
                </div>
                <div className={classes.headerBlock}>
                    <div className={classes.headerCount}>
                        <img src={coin} alt={''} className={classes.coin}/>
                        {balance}
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.1665 9.16699V5.00033C9.1665 4.54009 9.5396 4.16699 9.99984 4.16699C10.4601 4.16699 10.8332 4.54009 10.8332 5.00033V9.16699H14.9998C15.4601 9.16699 15.8332 9.54009 15.8332 10.0003C15.8332 10.4606 15.4601 10.8337 14.9998 10.8337H10.8332V15.0003C10.8332 15.4606 10.4601 15.8337 9.99984 15.8337C9.5396 15.8337 9.1665 15.4606 9.1665 15.0003V10.8337H4.99984C4.5396 10.8337 4.1665 10.4606 4.1665 10.0003C4.1665 9.54009 4.5396 9.16699 4.99984 9.16699H9.1665Z" fill="#FFC629"/>
                    </svg>
                </div>
            </div>
            <div className={classes.visBlock}>
                <div className={classes.block}>
                    {levels.map((item, number)=>
                        <div className={classes.level+' '+(activeLevel===(100-number) && classes.levelActive)}
                             key={item.id}
                             style={styles[(number) % 4]}
                             ref={activeLevel===100-number?ref:null}
                             onClick={()=>{if(activeLevel===(100-number))setOpenStartLevel(true)}}
                        >
                            {activeLevel >= 100 - number ? <div className={classes.levelText}>{100 - number}</div> :
                                <svg className={classes.levelBlock} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 8H20C20.5523 8 21 8.44772 21 9V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V9C3 8.44772 3.44772 8 4 8H6V7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7V8ZM11 15.7324V18H13V15.7324C13.5978 15.3866 14 14.7403 14 14C14 12.8954 13.1046 12 12 12C10.8954 12 10 12.8954 10 14C10 14.7403 10.4022 15.3866 11 15.7324ZM16 8V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V8H16Z" fill="#AE9367"/>
                                </svg>
                            }
                            <div className={classes.levelProgress}>
                                <div className={classes.levelProgress1+' '+(((activeLevel > 100 - number) || (activeLevel===100-number && info.stage > 1)) && classes.levelProgressCheck)}></div>
                                <div className={classes.levelProgress2+' '+(((activeLevel > 100 - number) || (activeLevel===100-number && info.stage > 2)) && classes.levelProgressCheck)}></div>
                                <div className={classes.levelProgress3+' '+((activeLevel > 100 - number) && classes.levelProgressCheck)}></div>
                            </div>
                        </div>
                    )}
                </div>
                <img alt={''} src={background} className={classes.background}/>
            </div>
            <CSSTransition in={openStartLevel > 0}
                           timeout={300}
                           classNames="openBlock"
                           unmountOnExit
            >
                <div className={classes.fon} onClick={()=>setOpenStartLevel(false)}></div>
            </CSSTransition>
            <CSSTransition in={openStartLevel > 0}
                           timeout={300}
                           classNames="openBonusMenu"
                           unmountOnExit
            >
                <div className={classes.startQuiz}>
                    <div className={classes.startQuizBlock1}>
                        <div className={classes.startQuizBlock1Header}>
                            <div className={classes.startQuizNumber}>Квиз {(info.level-1)*3+info.stage}</div>
                            <svg className={classes.close} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={()=>setOpenStartLevel(false)}>
                                <path d="M11.9997 10.5865L16.2424 6.34382C16.6329 5.9533 17.2661 5.9533 17.6566 6.34382C18.0471 6.73435 18.0471 7.36751 17.6566 7.75803L13.4139 12.0007L17.6566 16.2433C18.0471 16.6338 18.0471 17.267 17.6566 17.6575C17.2661 18.048 16.6329 18.048 16.2424 17.6575L11.9997 13.4149L7.75706 17.6575C7.36654 18.048 6.73338 18.048 6.34286 17.6575C5.95233 17.267 5.95233 16.6338 6.34286 16.2433L10.5855 12.0007L6.34285 7.75804C5.95232 7.36751 5.95233 6.73435 6.34285 6.34382C6.73338 5.9533 7.36654 5.9533 7.75707 6.34383L11.9997 10.5865Z" fill="white"/>
                            </svg>
                        </div>
                        <img src={fini} alt={''} className={classes.fini}/>
                    </div>
                    <div className={classes.startQuizBlock2}>
                        <div className={classes.prizeBlock}>
                            <img src={prize} alt={''} className={classes.prize}/>
                            До награды осталось {info.stage === 1 && <>3 квиза</>}{info.stage === 2 && <>2 квиза</>}{info.stage === 3 && <>1 квиз</>}!
                        </div>
                        <Button active={true} onClick={play}>Играть</Button>
                    </div>
                </div>
            </CSSTransition>
            <QuizGame open={openQuiz} item={quiz} close={()=>{setOpenQuiz(false); setOpenStartLevel(false)}}/>
        </div>
    );
};

export default Game;